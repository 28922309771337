<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <!-- <el-select v-model="select.is_stop" placeholder="状态" clearable style="width:140px" @change="curr=1;getList()">
                <el-option label="启用" value="1"></el-option>
                <el-option label="禁用" value="2"></el-option>
            </el-select> -->
            <el-input placeholder="车主姓名" style="width:200px" v-model.trim="select.realname" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" @input="curr=1;getList()"></el-input>
            <el-input placeholder="联系电话" style="width:200px" v-model.trim="select.tel" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" @input="curr=1;getList()"></el-input>
            <el-input placeholder="车牌号" style="width:200px" v-model.trim="select.car_no" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" @input="curr=1;getList()"></el-input>
            <!-- <el-button type="primary" block size="small" style="margin-left:auto" @click="edit()">新建</el-button> -->
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="car.car_no" label="车牌号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="car.realname" label="车主姓名" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="car.tel" label="车主电话" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="user.name" label="客户名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="产品详情" align="center" show-overflow-tooltip>
                <template slot-scope="scope">

                    <el-popover trigger="hover" placement="top" v-if="scope.row.carCareProduct&&scope.row.carCareProduct.length>0">
                        <el-table :data="scope.row.carCareProduct" size="mini" style="width: 100%">
                            <el-table-column prop="product_name" label="产品名称"></el-table-column>
                            <el-table-column prop="product_amount" align="center" label="产品金额"></el-table-column>
                            <el-table-column label="产品图片">
                                <template slot-scope="scope">
                                    <el-image v-if="scope.row.product_img_arr&&scope.row.product_img_arr.length>0" style="width: 40px; height: 40px" :src="getImgUrl(scope.row.product_img_arr[0])" :preview-src-list="getImgUrl(scope.row.product_img_arr,'arr')" fit="cover"></el-image>
                                    <p v-else>暂无</p>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div slot="reference" class="name-wrapper">
                            <i class="el-icon-s-ticket" style="color:#4877E6;font-size: 18px;"></i>
                        </div>
                    </el-popover>
                    <p v-else>无</p>

                </template>
            </el-table-column>

            <!-- <el-table-column prop="product.name" label="产品名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="product_cost" label="产品用量" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="产品图片" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image v-if="scope.row.product_img_arr&&scope.row.product_img_arr.length>0" style="width: 50px; height: 50px" :src="getImgUrl(scope.row.product_img_arr[0])" :preview-src-list="getImgUrl(scope.row.product_img_arr,'arr')" fit="cover"></el-image>
                    <p v-else>暂无</p>
                </template>
            </el-table-column> -->
            <el-table-column prop="remind_time" label="短信提醒时间" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{scope.row.remind_time_fmt}}
                </template>
            </el-table-column>
            <el-table-column prop="tel_time" label="电话提醒时间" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{scope.row.tel_time_fmt}}
                </template>
            </el-table-column>
            <el-table-column prop="next_time" label="下次保养时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="note" label="备注" align="center" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="操作" align="center" >
                <template slot-scope="scope">
                    <el-button plain type="success" size="small" icon="el-icon-edit-outline" @click="edit(scope.row)"></el-button>
                    <el-button plain type="danger" size="small" icon="el-icon-delete" @click="handleDel(scope.row.id)"></el-button>
                </template>
            </el-table-column> -->
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data: function() {
        return {
            search:'',
            searchValue: '', //导入input值
            realname:'',
            select: {},
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        this.getList();
    },
    methods: {
        getList: function() {
            this.http.post('/admin.carCare/index', {
                curr: this.curr,
                row: this.row,
                realname:this.select.realname,
                tel:this.select.tel,
                car_no:this.select.car_no,
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
                this.$nextTick(() => {
                    this.$refs.table.doLayout()
                })
            })
        },
        // 图片转换
        getImgUrl(item, type) {
            var url;
            var urlArr=[]
            if (type == 'arr') {
                item.forEach((i) => {
                    urlArr.push(this.domain + i);
                })
                return urlArr;
            } else {
                url = this.domain + item;
                return url;
            }
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
